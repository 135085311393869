.errorMsg {
  display: flex;
  flex-direction: row-reverse;
}

.alertInfo {
  display: flex;
  flex-direction: row-reverse;
}

.errorMsg > .ant-message-notice-content {
  cursor: pointer;
  border-style: solid;
  border-width: 0.7px;
  border-color: red;
  white-space: break-spaces;
  word-wrap: break-word;
}

.alertInfo > .ant-message-notice-content {
  cursor: pointer;
  border-style: solid;
  border-width: 0.7px;
  white-space: break-spaces;
  word-wrap: break-word;
}

.loadingInfo {
  display: flex;
  flex-direction: row;
}