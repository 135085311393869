.studio-add-new {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  justify-content: center;
  padding: 10px;
  margin-top: 25vh;
}

.project-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 10px);
  padding: 10px;
}

.CourseOverview-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 90px);
}

.Modules-main-content {
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 110px);
}

@media screen and (max-width: 600px) {
  .CourseOverview-root {
    max-height: calc(100vh - 130px);
  }
}