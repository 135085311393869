
.chat-markdown * {
  font-family: 'Outfit', serif;
  line-height: 1.6;
}

.chat-markdown h1 {
  font-family: 'Outfit', serif;
  line-height: 1.9;
  font-size: 21x;
}

.chat-markdown h2 {
  font-family: 'Outfit', serif;
  line-height: 1.9;
  font-size: 19px;
}

.chat-markdown h3 {
  font-family: 'Outfit', serif;
  line-height: 1.9;
  font-size: 17px;
}

.chat-markdown h4 {
  font-family: 'Outfit', serif;
  line-height: 1.9;
  font-size: 16px;
}

.chat-markdown p {
  font-size: 15px;
  white-space: break-spaces;
}

.chat-markdown div {
  font-size: 15px;
  margin-bottom: 1em;
}

.chat-markdown li {
  line-height: 1.6;
  font-size: 15px;
}

.chat-markdown li p {
  margin-top: 0.5em;
  margin-bottom: 0;
}

.chat-markdown li {
  line-height: 1.6;
  font-size: 15px;
}

.chat-markdown table {
  border-collapse: collapse;
  width: 100%;
  margin: 16px 0;
}

.chat-markdown th {
  font-weight: 600;
  text-align: left;
  padding: 12px;
  border: 1px solid #ddd;
}

.chat-markdown td {
  padding: 12px;
  border: 1px solid #ddd;
}

.chat-markdown tr:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .chat-markdown table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

